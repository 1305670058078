.mantine-PillGroup-group {
  flex-wrap: wrap !important;
}

@media (max-width: 992px) {
  .setting-header-title {
   width: 100%;
   justify-content: center;
  }
}

@media (min-width: 992px) {
  .setting-header {
    border-right: 2px solid rgb(230, 230, 230);
  }
  .setting-main {
    margin-left: 16px;
  }
}
