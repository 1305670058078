.containerPrint {
  height: 100%;
  margin-left: 40px !important;
  margin-right: 30px !important;
  margin-top: 10px !important;
  font-family: "Times New Roman", Times, serif;
}
.contentMain {
  width: 100%;
  position: relative;
  margin: 0 auto;
  margin-right: 20px !important;
  padding-right: 20px !important;
  padding-top: 10px !important;
}
.text {
  margin: 0 !important;
  /* line-height: 1.6;  */
}
.ProductTable {
  width: 100%;
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  table {
    width: 100%;
  }
  td {
    padding-left: 4px;
    padding-right: 4px;
    font-size: 14px;
  }
}
.logo {
  width: 90%;
  margin: 0 auto;
}
.backgroudHacom {
  position: absolute;
  width: 100%;
  z-index: -1;
  opacity: 0.1;
  top: 40%;
}
