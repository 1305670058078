.control {
  display: block;
  width: 100%;
  padding: 0px 15px !important;
  color: var(--mantine-color-text);
  font-size: 12.5px;

  &:hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-7)
    );
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  }
}

.link {
  font-weight: 500;
  display: block;
  text-decoration: none;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  padding-left: var(--mantine-spacing-md);
  margin-left: var(--mantine-spacing-xl);
  font-size: 12px;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  border-left: 1px solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));

  &:hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-7)
    );
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
    cursor: pointer;
  }
  &[data-active] {
    &,
    &:hover {
      background-color: var(--mantine-color-blue-light);
      color: var(--mantine-color-blue-light-color);

      & .linkIcon {
        color: var(--mantine-color-blue-light-color);
      }
    }
  }
}

.link2 {
  font-weight: 500;
  display: block;
  text-decoration: none;
  font-size: 12px;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  padding: 10px 15px;
  margin-bottom: 3px;
  width: 250px;
  transition: 100ms ease-out;
  border-radius: 10px;

  /* &[data-active] {
    &:hover {
      background-color: var(--mantine-color-blue-light);
      color: var(--mantine-color-blue-light-color);
      border-radius: 8px;

      & .linkIcon {
        color: var(--mantine-color-blue-light-color);
      }
    }
  } */
}

.custom_hover:hover {
  background-color: rgba(173, 179, 183, 0.308);
  color: #000000;
  border-radius: 10px;

}

.link2:hover {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  cursor: pointer;
}
.chevron {
  transition: transform 200ms ease;
}

.links {
  min-width: "80px";
  max-width: "100%";
  transition: "min-width 0.3s ease";
}
.activeBox {
  position: relative;
  border-radius: 10px;
  background-color: #006bb8;
}

.notActive {
  position: relative;
  border-radius: 0;
  background-color: transparent;

}