.button {
    &:disabled,
        &[data-disabled] {
            border-color: #d1d1d1;
            color: #494848;
        }
    }

.text_input>div>input {
    font-weight: 600;
    color: #000000;
    &:disabled,
        &[data-disabled] {
        border-color: #d1d1d1;
        color: #000000;
            font-weight: 600;
    }
}