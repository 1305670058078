.container {
  /* width: 270px !important; */
  width: 80mm !important;
  height: auto;
  /* border: 1px solid #000; */
  padding-top: 8px;
  /* margin-left: 2px;
  margin-right: 2px; */
  margin: 0 4px;
  margin-right: 5px;
  color: #000;
}
.textHeader {
  text-align: center;
}
/* style.module.css */
.text {
  margin: 0; /* Removes any default margin */
  line-height: 1.6; /* Adjust line height for closer text lines */
  /* font-weight: 400; */
}
