.main {
  padding: 0px 60px;
  font-family: "Times New Roman", Times, serif;
}

.cText {
  color: #EA242A;
  font-weight: bold;
}

.fw {
  font-weight: bold;
}

.inline p {
  display: inline;
}

.indent {
  text-indent: 32px;
}

.mb {
  margin-bottom: -10px;
}

.mbl {
  margin-bottom: -2.5px;
}

.mbt {
  margin-bottom: -25px;
}

.mbt2 {
  margin-bottom: -10px;
}

.mbt3 {
  margin-bottom: -20px;
}

.mtt {
  margin-top: -15px;
}

.mtt2 {
  margin-top: -5px;
}

.mtt3 {
  margin-top: -10px;
}

.mt {
  margin-top: 20px;
}

.mtl {
  margin-top: 5px;
}

.mtl2 {
  margin-top: 15px;
}

.italic {
  font-style: italic;
}

.flex {
  display: flex;
  gap: 5px;
}

.align { 
  display: flex;
  gap: 5px;
  align-items: center;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid grey;
  margin-top: 10px;
}

.table th, .table td {
  border: 1px solid grey;
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #EA242A;
  margin-bottom: -25px;
}

.header div div h3 {
  color: #EA242A;
}

.header div .line {
  margin-bottom: -27.5px;
}

.header div div {
  display: flex;
  gap: 5px;
}

.header div div div:nth-child(2) {
  margin-left: 20px;
}

.body {
  margin-top: 32.5px;
}

.title {
  text-align: center;
}

.signature {
  display: flex;
  justify-content: space-around;
}

.signature div {
  text-align: center;
}

.customImg {
  margin-top: 40px;
}

.bdRed {
  padding-bottom: 30px;
}