.navbar {
  width: 100%;
}
.item {
  position: relative;
}
.Dropdown {
  position: absolute;
  z-index: -1;
  width: 100%;
  margin-top: 32px;
  margin-left: 5px;
  padding: 20px 0;
  height: 100vh;
  background-color: #f8f9fa;
}

.title {
  padding: 0 16px 16px;
}
.link {
  color: #fff;
  background-color: #228be6;
  padding: 0;
}
.icon {
  color: #495057;
  cursor: pointer;
  padding: 7px;
  width: 30px;
  border-radius: 7px;
  /* &:hover {
          background-color: red;
        } */
  transition: 100ms ease-in-out;

  &:hover {
    transition: 100ms ease-in-out;
    background-color: #ebebeb9e;
  }
}

.control {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.active {
  background-color: #228be61a;
  color: #228be6;
}

.search {
  cursor: pointer;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: 100ms ease-in-out;
  outline: none;
}

.commonLink {
  text-decoration: none;
  color: #000;
}
.input_search {
  margin-top: 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search_model {
  margin-top: 10px;
}
.search_value {
  padding: 7px 16px;
  display: block;
  transition: 50ms ease-in-out;
  &:hover {
    background-color: #9c9c9c1a;
    transition: 100ms ease-in-out;
  }
}

.search_value_first {
  padding: 7px 16px;
  display: block;
  border-radius: 4px;
  background-color: #228be6;
  color: #fff;
  transition: 100ms ease-i;
}
