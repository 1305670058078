.containerPrint {
  height: 100%;
  margin-left: 40px !important;
  margin-right: 30px !important;
  margin-top: 10px !important;
  margin: 0 auto;
}
.contentMain {
  width: 100%;
  position: relative;
  margin: 0 auto;
  margin-right: 20px !important;
  padding-right: 20px !important;
  padding-top: 10px !important;
}
.logo {
  width: 90%;
  margin: 0 auto;
}
.backgroudHacom {
  position: absolute;
  width: 100%;
  z-index: -1;
  opacity: 0.1;
  top: 40%;
}

.text {
  margin: 0 !important;
  /* line-height: 1.6;  */
}

.warehouseCode {
  p {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
  }
}

.customerInformation {
  width: 100%;
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  table {
    width: 100%;
  }
  td {
    padding-left: 4px;
    padding-right: 4px;
    font-size: 14px;
  }
}
/* table Sản phẩm */
.ProductMain {
  table,
  th,
  td {
    border: 0.8px solid #313131;
    border-collapse: collapse;
  }
  table {
    width: 100%;
  }
  th {
    font-weight: 400;
    padding: 0 2px;
    font-size: 14px;
  }
  td {
    text-align: start;
    padding-left: 2px;
    padding-right: 2px;
    min-width: "50px" !important;
    padding: 0 2px;
    font-size: 14px;
  }
}
.thNameProduct {
  /* max-width: 150px; */
  width: 400px;
}
