.main {
    padding: 0px 60px;
    font-family: "Times New Roman", Times, serif;
}

.cText {
    color: #EA242A;
    font-weight: bold;
}

.header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EA242A;
    margin-bottom: -25px;
}

.header div div h3 {
    color: #EA242A;
}

.header div .line {
    margin-bottom: -27.5px;
}

.header div div, .infoForm div div, .infoCust div, .infoCust div div {
    display: flex;
    gap: 5px;
}

.header div div div:nth-child(2) {
    margin-left: 20px;
}

.body {
    margin-top: 50px;
}

.body div:nth-child(1) {
    text-align: center;
}

.infoForm {
    display: flex;
    justify-content: space-between;
}

.infoForm div div p:nth-child(1), .processingMethod, .note p:nth-child(1) {
    font-weight: bold;
}

.infoForm div div {
    margin-bottom: -25px;
}

.titleCust {
    margin-top: -5px;
    text-indent: 32px;
}

.infoCust {
    margin-top: -17.5px;
}

.infoCust .nameAndPhone {
    gap: 100px;
}

.infoCust div p:nth-child(1) {
    font-weight: bold;
}

.addr {
    margin-top: -25px;
}

.mainProcessingMethod {
    display: flex;
    gap: 50px;
}

.mainProcessingMethod div {
    align-items: center;
    display: flex;
    gap: 5px;
}

.table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid grey;
    margin-top: 10px;
}

.table th, .table td {
    border: 1px solid grey;
    text-align: center;
}

.note {
    display: flex;
    gap: 5px;
    margin-top: 10px;
}

.importantNote:nth-child(1) {
    margin-top: -10px;
}

.importantNote p {
    display: inline;
    padding-right: 5px;
}

.fw {
    font-weight: bold;
}

.daySignature {
    display: flex;
    width: 100%;
    margin-bottom: -30px;
    margin-top: 30px;
}

.daySignature div {
    width: 50%;
    text-align: center;
}

.signature {
    display: flex;
    justify-content: space-around;
}

.signature div {
    text-align: center;
}

.signature div p {
    margin-bottom: -17.5px;
}
  
.bdRed {
    padding-bottom: 30px;
}