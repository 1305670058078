/* Phong cách cho thẻ thông tin đơn hàng */
.orderInfoStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 5px;
}

.infoLabelStyle {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.infoValueStyle {
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.selectStyle {
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  width: 100%;
}

.optionStyle {
  padding: 10px;
}
