.main {
  padding: 0px 60px;
  font-family: "Times New Roman", Times, serif;
}

.cText {
  color: #ea242a;
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.mt15 {
  margin-top: 15px;
}

.mtt20 {
  margin-top: -20px;
}

.mtt15 {
  margin-top: -15px;
}

.mbt15 {
  margin-bottom: -15px;
}

.mbt25 {
  margin-bottom: -25px;
}

.fw {
  font-weight: bold;
}

.flexGap5 {
  display: flex;
  gap: 5px;
}

.flexGap50 {
  display: flex;
  gap: 50px;
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ea242a;
  margin-bottom: -25px;
}

.header div div h3 {
  color: #ea242a;
}

.header div .line {
  margin-bottom: -27.5px;
}

.header div div {
  display: flex;
  gap: 5px;
}

.header div div div:nth-child(2) {
  margin-left: 50px;
}

.body {
  margin-top: 50px;
}

.title {
  text-align: center;
}

.info div p:nth-child(1) {
  font-weight: bold;
}

.table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid grey;
  margin-top: 30px;
}

.table th,
.table td {
  border: 1px solid grey;
  text-align: center;
}

.signature {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}

.signature div {
  text-align: center;
}

.signature div p {
  margin-bottom: -17.5px;
}

.width5 {
  width: 75px;
}

.customImg {
  margin-top: 40px;
}

.bdRed {
  padding-bottom: 30px;
}
