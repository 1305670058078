.title {
  font-family: Greycliff CF, var(--mantine-font-family);
  font-weight: 900;
}
.logo {
  top: 20px;
  left: 40px;
  position: absolute;
  z-index: 3;
  > img {
    filter: drop-shadow(2px 2px 0px #fff);
  }
}
.form {
  width: 400px;
}
.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  overflow: hidden;
  background-color: #fbfbfb;
}
.content {
  border-radius: 10px;
  width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.images {
  flex: 0 0 55%;
    width: 55%;
  height: 100vh;
}
.imageUser {
  position: absolute;
  width: 35%;
  left: 5%;
}
.content_login {
  flex: 0 0 45%;
    max-width: 45%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.title {
  /* position: absolute;
  top: -100px; */
  font-size: 1.6rem;
  padding: 0 10px;
  white-space: nowrap;
  margin-bottom: 40px;
}
.box_Image {
  flex: 0 0 50%;
  max-width: 50%;
}

.logo_title {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
  justify-content: center;
  border-radius: 50%;
  background-color: #339af0;
}
.button_submit {
  box-shadow: 8px 8px 2px rgba(0, 0, 0, 0.21);
  transition: 300ms ease-in-out;
  &:hover {
    box-shadow: none;
  }
}

.outTop {
  animation: rotate 20s linear infinite;
  transform-origin: 13px 25px;
}
.inTop {
  animation: rotate 17s linear infinite;
  transform-origin: 13px 25px;
}

@keyframes rotate {
  0% {
    transform: scale(1.7) rotate(0deg);
  }
  100% {
    transform: scale(1.7) rotate(360deg);
  }
}
@media only screen and (max-width: 480px) {
  .content {
    border-radius: 10px;
    width: 350px;
  }
}

@media only screen and (max-width: 70rem) {
  .images {
    display: none;
    flex: none;
    width: 0;
  }
  .imageUser {
    display: none;
  }
  .content_login {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
  }
  .form {
    max-width: 340px;
    padding: 10px;
  }
  .title {
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 70rem) {
  .content {
    border-radius: 10px;
    padding: 0 20px;
  }
}