/* body div{
  font-size: 15px !important;
} */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.flex-none {
  flex: none !important;
}

section.mantine-Modal-content{
  flex: none !important;
}

.mantine-Modal-header{
  background-color: #0c68b4 !important;
  color: #fff !important;
}

.mantine-Modal-header svg{
  color: #fff !important;
} */

@media (min-width: 1200px) {
  .col-lg-2-4 {
    width: 20%;
  }
}
.d-flex {
  display: flex;
  align-items: center;
}
.align-items-center {
  align-items: center;
}

.e-grid .e-focused:not(.e-menu-item):not(.e-editedbatchcell) {
  box-shadow: none;
}

.mantine-Modal-header {
  background-color: #0c68b4 !important;
  color: #fff !important;
}

.mantine-Modal-header svg {
  color: #fff !important;
}
/* .mantine-AppShell-main{

} */
/* section.mantine-Modal-content {
  flex: none !important;
} */

/* ag-grid */

/* .ag-theme-quartz .ag-header-cell {
  font-size: 13.5px;
  font-weight: bold;
  text-align: center;
}
.ag-theme-quartz .ag-header-group-cell {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
.ag-header-cell-comp-wrapper {
  display: block !important;
}
.ag-header-group-cell-label,
.ag-header-cell-label {
  display: block !important;
} */
.custom-modal-body {
  display: flex;
  justify-content: center;
}
.custom-modal-title {
  margin: 0 auto;
  margin-right: -20px;
}
/* css thông báo */
.custom-notification {
  font-size: 18px;
  padding: 15px;
  width: 450px;
}

.custom-notification .mantine-Notification-title {
  font-size: 14.6px;
  font-weight: 600;
}
.custom-notification .mantine-Notification-description {
  font-size: 13.6px;
}

body::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(-45deg, #8f8f8f, #b4b4b4);
  border-radius: 50px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #757575;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6e6e6e;
}
