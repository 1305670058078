.main {
    padding: 0px 60px;
    font-family: "Times New Roman", Times, serif;
}

.cText {
    color: #EA242A;
    font-weight: bold;
}

.fw {
    font-weight: bold;
}

.header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EA242A;
    margin-bottom: -25px;
}

.header div div h3 {
    color: #EA242A;
}

.header div .line {
    margin-bottom: -27.5px;
}

.header div div {
    display: flex;
    gap: 5px;
}

.header div div div:nth-child(2) {
    margin-left: 50px;
}

.body {
    margin-top: 50px;
}

.body .title {
    text-align: center;
}

.infoForm {
    display: flex;
    justify-content: space-between;
}

.infoForm div div p:nth-child(1) {
    font-weight: bold;
    margin-right: 5px;
}

.infoForm div div p {
    display: inline;
    word-break: break-all;
}

.table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid grey;
    margin-top: 30px;
}

.table th, .table td {
    border: 1px solid grey;
    text-align: center;
}

.note1 {
    display: flex;
    gap: 5px;
    margin-top: 10px;
}

.note2 {
    display: flex;
    margin-top: -20px;
}

.note1 p:nth-child(1), .note2 p:nth-child(1) {
    font-weight: bold;
}

.careful {
    display: flex;
    gap: 5px;
    margin-top: -20px;
}

.careful div p:nth-child(1) {
    font-weight: bold;
}

.careful div p {
    margin-bottom: -12.5px;
}

.signature {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
}

.signature div {
    text-align: center;
}

.signature div p:nth-child(1) {
    font-weight: bold;
    margin-bottom: -12.5px;
}

.signature div p:nth-child(2) {
    font-style: italic;
}

.importantNote:nth-child(1) {
    margin-top: -10px;
}

.importantNote p {
    display: inline;
    padding-right: 5px;
}

.customImg {
    margin-top: 40px;
}

.bdRed {
    padding-bottom: 30px;
}
