.voucher-card {
  width: 95%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  overflow: hidden;
}

.voucher-card:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
